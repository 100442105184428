
export const HourCategories = [
    {label: "Hours in BG", value: "BG"},
    {label: "Hours of Strypes Portugal", value: "PT"},
    {label: "Hours of Front Office NL", value: "FO_NL"},
    {label: "Hours of Front office US", value: "FO_USA"},
    {label: "Account Delivery", value: "AD"},
]


export const LocationToDefaultCategory: Record<string, string> =
    {
        "BG": "BG",
        "PT": "PT",
        "NL": "Account Delivery",
        "USA": "Account Delivery"
    }